import { AppBar } from "react-admin"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import AppBarProjectSelect from "./AppBarProjectSelect"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
})

const CustomAppBar = (props: any) => {
  const classes = useStyles()
  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {/*Deloitte*/}
      {/*<Logo />*/}
      <span className={classes.spacer} />
      <AppBarProjectSelect />
    </AppBar>
  )
}

export default CustomAppBar
