import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { projectState } from "../atoms/application"
import { useRecoilState } from "recoil"
import { useCallback } from "react"
import MenuItem from "@material-ui/core/MenuItem"
import { Project } from "../enums/project"
import { makeStyles, ThemeProvider } from "@material-ui/core/styles"
import { isDevelopment } from "../utils"
import { useRedirect } from "react-admin"
const useStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: 10,
    minWidth: 120,
  },
}))

const AppBarProjectSelect = () => {
  const classes = useStyles({})
  const redirect = useRedirect()
  const [project, setProject] = useRecoilState(projectState)

  const handleChange = useCallback(
    (event) => {
      redirect("/")
      setProject(event.target.value)
    },
    [setProject, redirect]
  )

  return (
    <ThemeProvider
      theme={{
        overrides: {
          MuiSelect: {
            icon: {
              color: "#4caf50",
            },
          },
          MuiFormLabel: {
            root: {
              color: "#4caf50",
            },
          },
          MuiInputBase: {
            root: {
              color: "#4caf50",
            },
          },
          MuiOutlinedInput: {
            notchedOutline: {
              borderColor: "#4caf50",
            },
          },
        },
      }}
    >
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="project-select-outlined-label">Project</InputLabel>
        <Select
          labelId="project"
          id="project"
          value={project}
          onChange={handleChange}
          label="Project"
        >
          {isDevelopment() && (
            <MenuItem value={Project.LOCALHOST}>{Project.LOCALHOST}</MenuItem>
          )}
          <MenuItem value={Project.ADEL_TEST_ZAPLO}>
            {Project.ADEL_TEST_ZAPLO}
          </MenuItem>
          <MenuItem value={Project.ZAPLO}>{Project.ZAPLO}</MenuItem>
          <MenuItem value={Project.VWFS_TEST}>{Project.VWFS_TEST}</MenuItem>
          <MenuItem value={Project.VWFS}>{Project.VWFS}</MenuItem>
          <MenuItem value={Project.EXPO_PROD}>{Project.EXPO_PROD}</MenuItem>
          <MenuItem value={Project.OVERENIOSOB_TEST}>
            {Project.OVERENIOSOB_TEST}
          </MenuItem>
          <MenuItem value={Project.OVERENIOSOB_PROD}>
            {Project.OVERENIOSOB_PROD}
          </MenuItem>
        </Select>
      </FormControl>
    </ThemeProvider>
  )
}

export default AppBarProjectSelect
