import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "material-ui-pickers"
import React from "react"
import { RecoilRoot } from "recoil"
import "./App.css"
import { AtomKeys } from "./enums/atomKeys"
import { Project } from "./enums/project"
import Pages from "./pages"
import { ThemeProvider } from "@mui/material"
import materialDeloitteTheme from "./theme/materialDeloitteTheme"

const init = () => {
  localStorage.setItem(AtomKeys.PROJECT, Project.ZAPLO)
}

init()

function App() {
  return (
    <RecoilRoot>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"cs"}>
        <ThemeProvider theme={materialDeloitteTheme}>
          <Pages />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </RecoilRoot>
  )
}

export default App
