import * as React from "react"
import { Card, CardContent, CardHeader } from "@material-ui/core"

const Dashboard = () => (
  <Card>
    <CardHeader title="Adel Admin" />
    <CardContent>Overview of all Adel instances</CardContent>
  </Card>
)

export default Dashboard
