import SaveIcon from "@mui/icons-material/Save"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import TextField from "@mui/material/TextField"
import { TransitionProps } from "@mui/material/transitions"
import * as React from "react"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} children={props.children} />
})

interface ICaseNoteDialog {
  open: boolean
  handleClose: () => void
  handleSubmit: (noteText: string) => void
  noteText: string
}

const CaseNoteDialog: React.FC<ICaseNoteDialog> = (props) => {
  const { open, handleClose, handleSubmit, noteText } = props

  // fallback to empty string when noteValue is not defined -> this way value typeof string is sent to API in any case
  const [noteValue, setNoteValue] = React.useState<string>(noteText || "")

  const handleNoteValueChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNoteValue(e.currentTarget.value)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="case-note-dialog"
      fullWidth
    >
      <DialogTitle>Poznámka</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={noteValue}
          onChange={handleNoteValueChange}
          multiline={true}
          maxRows={Infinity}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          onClick={() => handleSubmit(noteValue)}
          disabled={noteText === noteValue}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CaseNoteDialog
