import * as React from "react"
import { downloadCSV, List, SelectArrayInput } from "react-admin"
import { ListProps } from "ra-ui-materialui/src/types"
import jsonExport from "jsonexport/dist"
// @ts-ignore could not find declaration file
import { DateInput } from "react-admin-date-inputs2"
import csLocale from "date-fns/locale/cs"
import DateFnsUtils from "@date-io/date-fns"
import subMonths from "date-fns/subMonths"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { useRecoilState } from "recoil"
import { projectState } from "../../atoms/application"
import ServicePortalColumns from "./ServicePortalColumns"
import { Project } from "../../enums/project"
import AdelColumns from "./AdelColumns"
import { mapCaseForOverview } from "../../utils"

const dateFns = new DateFnsUtils()

const choices = [
  { id: "ERROR", name: "ERROR" },
  { id: "CASE_FINISHED", name: "CASE_FINISHED" },
  { id: "ANALYTICA_FINISHED", name: "ANALYTICA_FINISHED" },
  { id: "FINISHED_WITHOUT_PAYMENT", name: "FINISHED_WITHOUT_PAYMENT" },
  {
    id: "FINISHED_WITHOUT_TRANSACTION_HISTORY",
    name: "FINISHED_WITHOUT_TRANSACTION_HISTORY",
  },
  { id: "NEW", name: "NEW" },
]

const serviceAccountsItems = [
  { id: "adel-fe", name: "adel-fe" },
  { id: "adel-vwfs", name: "adel-vwfs" },
  { id: "zaplo-service-account", name: "zaplo-service-account" },
  { id: "expobank", name: "expobank" },
]

const postFilters = [
  <DateInput
    label="Date from"
    source="dateFrom"
    alwaysOn
    options={{ format: "dd.MM.yyyy" }}
  />,
  <DateInput
    label="Date to"
    source="dateTo"
    alwaysOn
    options={{ format: "dd.MM.yyyy" }}
  />,
  // <CheckboxGroupInput alwaysOn source="status" />,
  <SelectArrayInput alwaysOn source="status" choices={choices} />,
  <SelectArrayInput
    alwaysOn
    source="serviceAccount"
    choices={serviceAccountsItems}
  />,
]

const exporter = (rows: any) => {
  const rowsForExport = rows.map((row: any) => {
    const { ...rowForExport } = row
    return mapCaseForOverview(rowForExport)
  })
  jsonExport(
    rowsForExport,
    {
      headers: [
        "id",
        "extID",
        "status",
        "bankCode",
        "serviceProvider",
        "createdAt",
        "errorDescription",
        "isPrivate",
        "wfStatuses",
        "serviceAccount",
        "note",
      ], // order fields in the export
    },
    (err, csv) => {
      const BOM = "\uFEFF"
      downloadCSV(`${BOM} ${csv}`, "review_of_cases") // download as 'posts.csv` file
    }
  )
}

export const CasesList = (props: ListProps) => {
  const [project] = useRecoilState(projectState)
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={csLocale}>
        <List
          {...props}
          sort={{ field: "id", order: "DESC" }}
          exporter={exporter}
          filters={postFilters}
          filterDefaultValues={{
            dateFrom: subMonths(dateFns.date(new Date()), 1).toISOString(),
            dateTo: dateFns.date(new Date()).toISOString(),
            status: ["ERROR", "CASE_FINISHED"],
            serviceAccount: [
              "adel-fe",
              "adel-vwfs",
              "zaplo-service-account",
              "expobank",
            ],
          }}
        >
          <>
            {(project === Project.VWFS_TEST || project === Project.VWFS) && (
              <ServicePortalColumns />
            )}
            {(project === Project.ZAPLO ||
              project === Project.ADEL_TEST_ZAPLO ||
              project === Project.EXPO_PROD ||
              project === Project.LOCALHOST) && <AdelColumns />}
          </>
        </List>
      </MuiPickersUtilsProvider>
    </>
  )
}
