import * as React from "react"
import { Datagrid, DateField, TextField, useListContext } from "react-admin"
import CaseNoteField from "./CaseNoteField"
import WorkFlowStatusField from "./WorkFlowStatusField"
import { mapCaseForOverview } from "../../utils"

const AdelColumns = () => {
  const { data } = useListContext()
  const mappedData: any = {}
  for (let dataKey in data) {
    mappedData[dataKey] = mapCaseForOverview(data[dataKey])
  }

  return (
    <Datagrid data={mappedData}>
      <TextField source="id" />
      <TextField source="extID" />
      <TextField source="status" />
      <TextField source="bankCode" />
      <TextField source="serviceProvider" />
      <DateField source="createdAt" locales={"cs"} showTime />
      <TextField source="errorDescription" />
      <WorkFlowStatusField source="wfStatuses" />
      <TextField source="serviceAccount" />
      <CaseNoteField source="note" />
    </Datagrid>
  )
}

export default AdelColumns
