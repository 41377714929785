import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface IWorkFlowStatusDialog {
  open: boolean
  handleClose: () => void
  statuses: string[]
}

const WorkFlowStatusDialog: React.FC<IWorkFlowStatusDialog> = ({
  open,
  handleClose,
  statuses,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="workflow-dialog"
    >
      <DialogTitle>{"Workflow stavy"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="workflow-dialog-description">
          <ul>
            {statuses.map((status: String) => (
              <li>
                {status === "CASE_CREATE" && "Žiadosť sa začala vytvárať"}
                {status === "CASE_CREATED" && "Žiadosť bola vytvorená"}
                {status === "CASE_INIT" && "Inicializácia ADEL FE"}
                {status === "CASE_INITIATED" && "ADEL FE bola načítaná"}
                {status === "ACCOUNTS_LOAD" && "Pred prvým prihlásením do IB"}
                {status === "ACCOUNTS_LOADED" && "Účty z IB sú načítané"}
                {status === "ACCOUNT_SELECTED" && "Je vybratý účet"}
                {status === "PAYMENT_CREATE" && "Inicializácia platby"}
                {status === "PAYMENT_FAILED" && "Platba zlyhala"}
                {status === "PAYMENT_CREATED" && "Platba bola vytvorená"}
                {status === "ACCOUNT_VERIFICATION" && "Začína sa overovať účet"}
                {status === "ACCOUNT_VERIFIED" && "Účet je overený"}
                {status === "TRANSACTIONS_DOWNLOAD" &&
                  "Transakcie sa začínajú sťahovať"}
                {status === "TRANSACTIONS_DOWNLOADED" &&
                  "Tranksakcie sú stiahnuté"}
                {status === "ANALYTICA_STARTED" &&
                  "Inicializuje sa datová analýza"}
                {status === "ANALYTICA_FINISHED" &&
                  "Datová analýza je dokončená"}
                {status === "SESSION_EXPIRED" && "Vyexpirovaná session"}
                {status === "SP_FINISHED" &&
                  "Service provider získal finish stav"}
                - {status}
              </li>
            ))}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default WorkFlowStatusDialog
