import simpleRestProvider from "ra-data-json-server"
import React, { useMemo } from "react"
import { Admin, Resource } from "react-admin"
import { useRecoilState } from "recoil"
import { projectState } from "../atoms/application"
import CustomLayout from "../layout/CustomLayout"
import { CasesList } from "../lists/caseslist/CasesList"
import { authProvider } from "../services/authProvider"
import { raDeloitteTheme } from "../theme/raDeloitteTheme"
import { fetchJson } from "../utils/fetchJson"
import { getCaseOverviewPath } from "../utils/paths"
import Dashboard from "./Dashboard"
import LoginPage from "./LoginPage"
import { BankIdList } from "../lists/bankid/BankIdList"
import { Paths } from "../enums/paths"

const dataProvider = simpleRestProvider("", fetchJson)

const Pages = () => {
  const [project] = useRecoilState(projectState)

  const pathCaseOverview = useMemo(() => {
    if (!project) {
      throw new Error("Project code has to be defined")
    }
    return getCaseOverviewPath(project)
  }, [project])

  return (
    <Admin
      loginPage={LoginPage}
      theme={raDeloitteTheme}
      authProvider={authProvider}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      layout={CustomLayout}
    >
      <Resource
        name={pathCaseOverview}
        list={CasesList}
        options={{ label: "List of cases" }}
      />
      <Resource
        name={Paths.CASE_BANKID_LIST}
        list={BankIdList}
        options={{ label: "BankId applications" }}
      />
    </Admin>
  )
}

export default Pages
