import { Datagrid, DateField, TextField, useListContext } from "react-admin"

const BankIdColumn = () => {
  const { data } = useListContext()
  // Get datagrid from react admin and create columns for bank id
  return (
    <Datagrid data={data}>
      <TextField source="id" />
      <TextField source="extid" />
      <TextField source="statusCode" />
      <DateField source="createdAt" locales={"cs"} showTime />
    </Datagrid>
  )
}

export default BankIdColumn
