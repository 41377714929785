export const isDevelopment = (): boolean => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return true
  }
  return false
}

export const isEmpty = (val: Object): boolean => {
  return !val || (val && Object.keys(val).length === 0)
}

export const goTo = (url: string): void => {
  const a = document.createElement("a")
  a.setAttribute("href", url)
  if (!isDevelopment()) {
    a.setAttribute("referrerpolicy", "strict-origin")
  }
  if (isDevelopment()) {
    a.setAttribute("referrerpolicy", "unsafe-url")
  }
  a.style.display = "none"
  document.body.appendChild(a)
  a.click()
}

export const mapCaseForOverview = (caseForOverview: any): any => {
  const {
    id,
    extID,
    status,
    bankCode,
    serviceProvider,
    createdAt,
    errorDescription,
    isPrivate,
    wfStatuses,
    serviceAccount,
    note,
  } = caseForOverview
  return {
    id,
    extID,
    status,
    bankCode,
    serviceProvider,
    createdAt,
    errorDescription,
    isPrivate,
    wfStatuses,
    serviceAccount,
    note,
  }
}
