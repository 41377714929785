import { Paper, TextField } from "@material-ui/core"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import { useState } from "react"
import { useRecordContext } from "react-admin"
import { Paths } from "../../enums/paths"
import { fetchJson } from "../../utils/fetchJson"
import CaseNoteDialog from "./CaseNoteDialog"

interface ICaseNoteFieldProps {
  source: string
}

interface INoteDialogPayload {
  id: number | null
  text: string
}

const generateNoteEndpointPath = (
  path: Paths.CASE_NOTES_CREATE | Paths.CASE_NOTES_UPDATE,
  caseId: number
) => {
  return `/${path}/${caseId}`
}

const handleNoteUpdate = async (
  note: INoteDialogPayload,
  caseId: number,
  shouldUpdateNote: boolean
) => {
  try {
    if (shouldUpdateNote) {
      // If note has id,
      // it means it already exists
      // -> handle UPDATE here
      await fetchJson(
        generateNoteEndpointPath(Paths.CASE_NOTES_UPDATE, caseId),
        {
          method: "PUT",
          body: JSON.stringify({
            text: note.text,
          }),
        }
      )

      return { success: true }
    } else {
      // Note without id means that it doesn't exist yet inside the database
      // -> handle note creation here
      await fetchJson(
        generateNoteEndpointPath(Paths.CASE_NOTES_CREATE, caseId),
        {
          method: "POST",
          body: JSON.stringify({
            text: note.text,
          }),
        }
      )

      return { success: true }
    }
  } catch (error) {
    console.error(error)
    throw new Error("Error while handling note update")
  }
}

const CaseNoteField: React.FC<ICaseNoteFieldProps> = (props) => {
  const record = useRecordContext()
  const [open, setOpen] = useState(false)
  const { noteId, note, id: caseId } = record

  const [caseNoteFieldValue, setCaseNoteFieldValue] = useState(note)
  const [shouldUpdateNote, setShouldUpdateNote] = useState(!!noteId)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async (noteText: string) => {
    const parsedNoteId = parseInt(noteId) || null

    const { success } = await handleNoteUpdate(
      { id: parsedNoteId, text: noteText },
      caseId as number,
      shouldUpdateNote
    )

    if (success) {
      setCaseNoteFieldValue(noteText)
      // We want to always update the note after first successful API update call
      // --> this handles the case where we would edit the same note multiple times before refetching (causing multiple insert calls)
      setShouldUpdateNote(true)
      handleClose()
    }
  }

  return (
    <>
      {/* Handle click on the Paper component, so that clicking anywhere in the Note area will trigger the popup */}
      <Paper
        elevation={0}
        onClick={handleClickOpen}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {caseNoteFieldValue ? (
          <>
            <TextField
              InputProps={{ readOnly: true, disableUnderline: true }}
              value={caseNoteFieldValue}
              style={{ cursor: "pointer" }}
            />
            <IconButton>
              <EditIcon />
            </IconButton>
          </>
        ) : (
          <IconButton>
            <AddIcon />
          </IconButton>
        )}
      </Paper>
      <CaseNoteDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        noteText={caseNoteFieldValue}
      />
    </>
  )
}

export default CaseNoteField
