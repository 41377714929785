import { AtomEffect } from "recoil"
import { info } from "../services/logger"

export const localStorageEffect =
  (key: string): AtomEffect<string | null> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      info(`Recoil init ${key} = ${savedValue}`)
      setSelf(savedValue)
    }

    onSet((newValue: string | null) => {
      info(`Recoil onSet ${key} = ${newValue}`)
      localStorage.setItem(key, newValue || "")
    })
  }

export const sessionStorageEffect =
  (key: string): AtomEffect<string | null> =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key)
    if (savedValue != null) {
      info(`Recoil init ${key} = ${savedValue}`)
      setSelf(savedValue)
    }

    onSet((newValue: string | null) => {
      info(`Recoil onSet ${key} = ${newValue}`)
      sessionStorage.setItem(key, newValue || "")
    })
  }
