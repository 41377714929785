import DateFnsUtils from "@date-io/date-fns"
import csLocale from "date-fns/locale/cs"
import { MuiPickersUtilsProvider } from "material-ui-pickers"
import { ListProps } from "ra-ui-materialui/src/types"
import { List } from "react-admin"
import BankIdColumn from "./BankIdColumn"

export const BankIdList = (props: ListProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={csLocale}>
      <List {...props}>
        <BankIdColumn />
      </List>
    </MuiPickersUtilsProvider>
  )
}
