export enum Project {
  LOCALHOST = "LOCALHOST",
  ADEL_TEST_ZAPLO = "ADEL_TEST_ZAPLO",
  ZAPLO = "ZAPLO",
  VWFS_TEST = "VWFS_TEST",
  VWFS = "VWFS",
  EXPO_PROD = "EXPO_PROD",
  OVERENIOSOB_TEST = "OVERENIOSOB_TEST",
  OVERENIOSOB_PROD = "OVERENIOSOB_PROD",
}
