import React from "react"
import { FunctionField } from "react-admin"
import Button from "@mui/material/Button"
import WorkFlowStatusDialog from "./WorkFlowStatusDialog"

const WorkFlowStatusField: React.FC<any> = (...props) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <FunctionField
      {...props}
      render={(record: any, source: any) => {
        // check if are send any statuses
        if (!record.wfStatuses) {
          return "Žiadny workflow status"
        }
        const statuses = record.wfStatuses.split(", ")
        return (
          <>
            <Button variant="outlined" onClick={handleClickOpen}>
              Zobraz
            </Button>
            <WorkFlowStatusDialog
              open={open}
              handleClose={handleClose}
              statuses={statuses}
            />
          </>
        )
      }}
    />
  )
}

export default WorkFlowStatusField
