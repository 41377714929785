import { Paths } from "../enums/paths"
import { IUser } from "../models/IUser"
import { AtomKeys } from "../enums/atomKeys"
import { sha512 } from "js-sha512"
import { goTo } from "../utils"

export const oldLogin = (username: string, password: string) => {
  const shaPassword = sha512(password)
  const request = new Request(Paths.LOGIN, {
    method: "POST",
    body: JSON.stringify({ username, password: shaPassword }),
    headers: new Headers({ "Content-Type": "application/json" }),
  })
  return fetch(request)
    .then((response) => {
      if (response.status === 204) {
        const user: IUser = {
          username: "",
          logged: true,
        }
        localStorage.setItem(AtomKeys.USER, JSON.stringify(user))
      }
    })
    .catch(() => {
      throw new Error("Network error")
    })
}

export const authProvider = {
  login: ({ username, password }: any) => {
    return oldLogin(username, password)
  },
  checkError: (error: any) => {
    const status = error.status
    if (status === 401 || status === 403) {
      return Promise.reject()
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  checkAuth: (params: any) => {
    // get user from localstorage
    const strUser = localStorage.getItem(AtomKeys.USER)
    const strIsAuthenticated = sessionStorage.getItem(AtomKeys.IS_AUTHENTICATED)
    if (strIsAuthenticated === "1") {
      return Promise.resolve()
    }
    if (strUser) {
      const user: IUser = JSON.parse(strUser) as IUser
      if (user.logged) {
        return Promise.resolve()
      }
    }
    return Promise.reject()
  },
  logout: () => {
    // check if we are logged via AZURE
    const strIsAuthenticated = sessionStorage.getItem(AtomKeys.IS_AUTHENTICATED)
    if (strIsAuthenticated === "1") {
      sessionStorage.setItem(AtomKeys.IS_AUTHENTICATED, "1")
      goTo("signout")
      return Promise.resolve()
    } else {
      // logged via database
      const user: IUser = {
        username: "",
        logged: false,
      }
      localStorage.setItem(AtomKeys.USER, JSON.stringify(user))
      return Promise.resolve()
    }
  },
  // getIdentity: (): Promise => Promise.resolve(),
  // authorization
  getPermissions: (params: any) => Promise.resolve(),
}
