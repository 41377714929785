import { createTheme } from "@mui/material/styles"
import { green } from "@mui/material/colors"

const materialDeloitteTheme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
  },
})

export default materialDeloitteTheme
