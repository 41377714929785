import { AtomKeys } from "../enums/atomKeys"
import { localStorageEffect, sessionStorageEffect } from "./index"
import { atom } from "recoil"
import { Project } from "../enums/project"

export const loggedState = atom({
  key: AtomKeys.USER,
  default: null,
  effects_UNSTABLE: [localStorageEffect(AtomKeys.USER)],
})

export const isAuthenticatedState = atom<string | null>({
  key: AtomKeys.IS_AUTHENTICATED,
  default: "0",
  effects_UNSTABLE: [sessionStorageEffect(AtomKeys.IS_AUTHENTICATED)],
})

export const projectState = atom<string | null>({
  key: AtomKeys.PROJECT,
  default: Project.LOCALHOST,
  effects_UNSTABLE: [localStorageEffect(AtomKeys.PROJECT)],
})
