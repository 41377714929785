import { defaultTheme } from "react-admin"
import merge from "lodash/merge"
import pink from "@material-ui/core/colors/pink"
import red from "@material-ui/core/colors/red"
import green from "@material-ui/core/colors/green"

export const raDeloitteTheme = merge({}, defaultTheme, {
  palette: {
    primary: green,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    RaLayout: {
      appFrame: {
        marginTop: "80px !important",
      },
    },
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: "white", // Some CSS
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: "black",
      },
    },
  },
})
