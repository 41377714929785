import Button from "@mui/material/Button"
import { makeStyles } from "@material-ui/core/styles"
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Paths } from "../enums/paths"
import { useRedirect } from "react-admin"
import { useRecoilState } from "recoil"
import { isAuthenticatedState } from "../atoms/application"
import { TextField } from "@mui/material"
import { oldLogin } from "../services/authProvider"
import { goTo } from "../utils"

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100%",
  },
  header: {
    display: "flex",
    height: "120px",
    background: "black",
    alignItems: "center",
  },
  video: {
    minHeight: "100%",
    minWidth: "100%",
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
  },
  poster: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  logo: {
    color: "white",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "50px",
    padding: "0px 40px",
    flexGrow: 1,
  },
  login: {
    margin: "0px 40px",
    "& button": {
      color: "white",
    },
  },
  oldLogin: {
    // background: "white",
    position: "absolute",
    left: "40px",
    top: "300px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    // "& button": {
    //   width: "150px",
    // },
  },
  desc: {
    zIndex: 2,
    position: "absolute",
    top: "40px",
    left: "40px",
    fontSize: "50px",
    color: "white",
  },
}))

const handleMSALSignIn = (event: SyntheticEvent) => {
  event.preventDefault()
  event.stopPropagation()
  goTo("/signin")
}

const isAuthenticated = async (): Promise<boolean> => {
  const request = new Request(Paths.IS_AUTHENTICATED, {
    method: "GET",
    headers: new Headers({ "Content-Type": "application/json" }),
  })
  return await fetch(request).then((response) => {
    return response.status === 204
  })
}

const LoginPage = () => {
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const classes = useStyles({})
  const redirect = useRedirect()
  const [, setAuthenticated] = useRecoilState(isAuthenticatedState)

  const handleOldLogIn = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()
      // do old login and redirect to dashboard
      if (name && password) {
        oldLogin(name, password).then(() => {
          redirect("/")
        })
      }
    },
    [name, password, redirect]
  )

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      setName(event.target.value)
    },
    [setName]
  )

  const handlePasswordChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      setPassword(event.target.value)
    },
    [setPassword]
  )

  useEffect(() => {
    isAuthenticated().then((resultAuthenticated) => {
      if (resultAuthenticated) {
        setAuthenticated("1")
        redirect("/")
      } else {
        setAuthenticated("0")
      }
    })
  }, [redirect, setAuthenticated])

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.logo}>Deloitte</div>
        <div className={classes.login}>
          <Button onClick={handleMSALSignIn} variant="contained">
            Login
          </Button>
        </div>
      </header>
      <section className={classes.poster}>
        <div className={classes.desc}>
          Adel
          <br /> Konzola
        </div>
        <video
          poster={process.env.PUBLIC_URL + "intro_placeholder.jpg"}
          autoPlay={true}
          loop={true}
          muted={true}
          className={classes.video}
        >
          <source
            src={process.env.PUBLIC_URL + "deloitte.mp4"}
            type={"video/mp4"}
            media={"all"}
          ></source>
        </video>
      </section>
      <section className={classes.oldLogin}>
        <TextField
          label={"name"}
          variant="filled"
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          label={"password"}
          value={password}
          onChange={handlePasswordChange}
        />
        <Button onClick={handleOldLogIn} variant="contained">
          Old login
        </Button>
      </section>
    </div>
  )
}

export default LoginPage
