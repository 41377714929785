import { fetchUtils } from "ra-core"
import { AtomKeys } from "../enums/atomKeys"

export const fetchJson = (
  url: string,
  options: fetchUtils.Options = {}
): Promise<any> => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" })
  }
  const project: string = localStorage.getItem(AtomKeys.PROJECT) || ""
  const headers = options.headers as Headers
  headers.set("X-Project", project)
  return fetchUtils.fetchJson(url, options)
}
