import { Datagrid, DateField, TextField, BooleanField } from "react-admin"
import * as React from "react"
import WorkFlowStatusField from "./WorkFlowStatusField"

const ServicePortalColumns = () => {
  return (
    <Datagrid>
      <TextField source="id" />
      <TextField source="extID" />
      <TextField source="status" />
      <TextField source="serviceProvider" />
      <TextField source="idCaseServicePortal" />
      <TextField source="ownerBankAccount" />
      <DateField source="createdAt" locales={"cs"} />
      <DateField source="createdAtServicePortal" locales={"cs"} showTime />
      <TextField source="errorDescription" />
      <BooleanField source="isPrivate" />
      <WorkFlowStatusField source="wfStatuses" />
      <TextField source="serviceAccount" />
    </Datagrid>
  )
}

export default ServicePortalColumns
